import { useState, useContext, useEffect, useCallback,  useRef } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import { ReactComponent as Opensea } from "./opensea.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import Mining from "./doin.mp3";

function App() {
  const audioRef = useRef(null);
  const [count, setCount] = useState(2);
  const [mintedCount, setMintedCount] = useState(0);

  const {
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
    contractDetails,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  const connectWallet = () => {
    audioRef.current.play();
    connectBrowserWallet()
  }

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);

  return (
    <>
    <audio ref={audioRef} playsInline autoPlay loop>
          <source src={Mining} type="audio/mpeg" />
        </audio>
      <div className="ass-container">
        <div className="ass-wrapper">
            <a
              href="https://opensea.io/collection/ass-like-that"
              target="_blank"
              rel="noreferrer"
              className="ass-link-item"
            >
              <Opensea />
            </a>
            <a
              href="https://twitter.com/AssLikeThat_wtf"
              target="_blank"
              rel="noreferrer"
              className="ass-link-item ass-twitter"
            >
              <Twitter />
            </a>
        </div>
        <h1>Ass Like That</h1>
        <div className="ass-form">  
          <h3>pfp juiCy butts joining to #fortheculture under cc0</h3>
          <h2>1 FREE MINT</h2>
          {/* {!!contractDetails && (
            <p className="counter">
              {mintedCount} / 3333
            </p>
          )} */}
          {!!userData ? (
            <>
              <div className="ass-form-counter">
                <button
                  onClick={handleCounter}
                  type="button"
                  className="greed-btn"
                  data-pos="minus"
                >
                  -
                </button>
                <span> {count} </span>
                <button
                  onClick={handleCounter}
                  type="button"
                  className="greed-btn"
                  data-pos="plus"
                >
                  +
                </button>
                <button
                  onClick={handleCustomMint}
                  type="button"
                  className="claim-btn"
                >
                  Mint
                </button>
              </div>
              <div className="greed-claimers">
                <button
                  onClick={() => mint(3)}
                  type="button"
                  className="claim-btn"
                >
                  Mint 3
                </button>
                <button
                  onClick={() => mint(5)}
                  type="button"
                  className="claim-btn"
                >
                  Mint 5
                </button>
                <button
                  onClick={() => mint(10)}
                  type="button"
                  className="claim-btn"
                >
                  Mint 10
                </button>
              </div>
              {transactionHash && (
                <a
                  href={`https://etherscan.io/tx/${transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                  className="claim-btn transaction-hash"
                >
                  See transaction
                </a>
              )}
            </>
          ) : (
            <button
              type="button"
              className="connect-wallet"
              onClick={connectWallet}
            >
              Connect Wallet
            </button>
          )}
          <p>after 1 Free butt price 0.0088</p>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
